import React from "react";
import styled, { css } from "styled-components";
import { Link } from "gatsby";

const Pagination = ({ numPages, currentPage, slugPrefix }) => {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? `${slugPrefix}/` : `${slugPrefix}/${(currentPage - 1).toString()}/`;
  const nextPage = `${slugPrefix}/${(currentPage + 1).toString()}/`;
  if (numPages <= 1) {
    return null;
  }

  const createPaginationObjects = (length, page, increment = 2) =>
    Array.from({ length }, (_, i) => ({
      link: `${slugPrefix}/${i + increment}/`,
      index: i + increment,
      current: page === i + increment,
    }));

  let navItems = [
    {
      link: `${slugPrefix}/`,
      index: 1,
      current: currentPage === isFirst,
    },
  ];
  if (numPages <= 5) {
    navItems = [
      ...navItems,
      ...Array.from({ length: numPages - 1 }, (_, i) => ({
        link: `${slugPrefix}/${i + 2}/`,
        index: i + 2,
        current: currentPage === i + 2,
      })),
    ];
  } else if (currentPage <= 3) {
    navItems = [
      ...navItems,
      ...createPaginationObjects(3, currentPage),
      {
        separator: true,
        index: "starter-separator",
      },
      {
        link: `${slugPrefix}/${numPages}/`,
        index: numPages,
        current: false,
      },
    ];
  } else if (currentPage > numPages - 3) {
    // If the current one is closer to the last one
    navItems = [
      ...navItems,
      {
        separator: true,
        index: "finisher-separator",
      },
      ...createPaginationObjects(4, currentPage, numPages - 3),
    ];
  } else {
    navItems = [
      ...navItems,
      {
        separator: true,
        index: "starter-separator",
      },
      ...createPaginationObjects(3, currentPage, currentPage - 1),
      {
        separator: true,
        index: "finisher-separator",
      },
      {
        link: `${slugPrefix}/${numPages}/`,
        index: numPages,
        current: false,
      },
    ];
  }

  return (
    <div className="pagination-wrapper section-inner">
      <hr className="styled-separator pagination-separator is-style-wide" aria-hidden="true" />

      <nav className="navigation pagination" role="navigation" aria-label="Posts">
        <div className="nav-links">
          <div className="pagination-button-container">
            {!isFirst && (
              <Link className={"next page-numbers"} to={prevPage}>
                <span className="nav-next-text">
                  Vanhemmat <span className="nav-short">artikkelit</span>
                </span>
                {"->"}
              </Link>
            )}
          </div>
          <NavItems>
            {navItems.map((item) => (
              <PaginationList key={item.link}>
                {item.separator ? (
                  <PaginationSeparator>...</PaginationSeparator>
                ) : (
                  <PaginationItem current={currentPage === item.index}>
                    {currentPage === item.index ? <span>{item.index}</span> : <Link to={item.link}>{item.index}</Link>}
                  </PaginationItem>
                )}
              </PaginationList>
            ))}
          </NavItems>
          <div className="pagination-button-container">
            {!isLast && (
              <Link className={"prev page-numbers"} to={nextPage}>
                <span aria-hidden="true">←</span>
                <span className="nav-prev-text">
                  Uusimmat <span className="nav-short">artikkelit</span>
                </span>
              </Link>
            )}
          </div>
        </div>
      </nav>
    </div>
  );
};

const NavItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaginationList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  height: 32px;
  padding-left: 0 !important;
  margin-left: 0 !important;

  a {
    text-decoration: none;
  }
`;

const PaginationItem = styled.li`
  min-width: 32px;
  height: 32px;
  font-weight: 400;
  margin: 0 10px;
  text-align: center;
  border-top: 1ps solid transparent;

  font-size: 2.4rem;
  font-weight: 700;
  line-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  @media (max-width: 576px) {
    min-width: 36px;
    height: 36px;
    margin: 0 4px;
  }

  a {
    color: #009864;
    text-decoration: none;
    box-shadow: none;
    text-shadow: none;
  }
  a: hover {
    color: #009864;
    text-decoration: none;
    box-shadow: none;
  }
  ${({ current }) =>
    current &&
    css`
      color: #000;
    `}
`;

const PaginationSeparator = styled.li`
  min-width: 19px;
  min-height: 48px;
  font-weight: 400;
  margin: 0 5px;
  padding-bottom: 5px;
  text-align: center;
  text-decoration: none;
  font-family: GT Walsheim Pro;
  font-size: 22px;
  letter-spacing: 0.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7c3aed;
  @media (max-width: 576px) {
    margin: 0;
  }
`;

export default Pagination;
